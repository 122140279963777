import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Video/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import VideoTable from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/photoTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "Recording Menu // Video",
  "path": "/Web_User_Interface/1440p_Serie/Recording/Video/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Take snapshots at an adjustable time interval and merge them in third-party software to create a TimeLapse video. You can also upload the snapshots with a fixed file name. This will always overwrite the last file and allow you to display a live image from your camera on your website.",
  "image": "./WebUI_1440p_SearchThumb_Tasks_Photoseries.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title="Recording Menu // Video" dateChanged="2021-12-12" author="Mike Polinowski" tag="INSTAR IP Camera" description="Take snapshots at an adjustable time interval and merge them in third-party software to create a TimeLapse video. You can also upload the snapshots with a fixed file name. This will always overwrite the last file and allow you to display a live image from your camera on your website." image="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.png" twitter="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/Video/" locationFR="/fr/Web_User_Interface/1440p_Series/Features/Video/" crumbLabel="Video" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "task-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#task-menu",
        "aria-label": "task menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Task Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "configure-a-planned-video-recording-on-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-a-planned-video-recording-on-sd-card",
        "aria-label": "configure a planned video recording on sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure a planned Video Recording on SD Card`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c9efc152b01216e3c7f0c2d5a7049475/e2e58/1440p_Settings_Task_Video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACnUlEQVQoz1XSUU/bVhjGcT4G0kYXbkoMXE0qoVIJVZPYcbCdxD6O45CkEEbiiA26EBAYRihlQ8m6i16stFLVAlWrdZumsWlsasei3u5uH2Cf5T8lpqi7+Ok50pEevTrvGRgfH0eSJOT5FurqMYnPHhNbetQ3XT/8n6h3yLQX5M36Q/S1E+zlDh9d+YDBwUFCoRADY2NjSOEw6does63vcPwj8lsnOP4xYuPoPcfYmyfYm0H27uf2fyC9uIM0EkaSeoONMTA6OkqPHJvGFQaOqWEZKllNxnxHV8ikYqQSUVKJadTYFGo8ip1NMSMrZNJlFNkgMnE9mLBXqCRVck4eS9ikM1n0dOaSYaRJiwKGlUc3DFKaTmpGZ0bXEUYOV62gKRrXJyNB4cjICJqmsbS0hOd5fbVqlVqthufVqFYXsXMOpmUjLBMhrL6sZTEnBPcXXSollejUe4WGYdBsNrlzZ4VGo0Fjtclqc43m2nr/7BYKWJZA2LlLGVNQKbs8uPcJC6Uo0RuTF0uRJBRFIec4pPUZssU69vZLhP8C23+O7b/Abb2isPt9oPWK4t6PmOtP8Vv7/H16xMqsQiQyERSGw2GSySS3b5fJ21ny1XWK998y2+lS7Jz3s9A+p9D+i0Kny2z7nNLXXazWKVvbLf79/YxHy1WuXfu4t+VgQllJkskVUTUDq7JKof0n+YM3uF/9gXvwOrD/K+69X3C+/A2rc47a+pkvNnz+6Z7xzP+UTPziDa+GJWxd5smGxbcNnflSHrNUxy7XEWUP0ctSjdznDxBbJ1S8Pdp2hV1znubiAj8dfsPdeRNbebeUsNT/VzsLSfy53t9LkEjEScoxFDkeSNxCFRWSxRVM3WE5GmUlFmcqMsmHQ0MMh0KEQsP8B0xNyO2On4FtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9efc152b01216e3c7f0c2d5a7049475/e4706/1440p_Settings_Task_Video.avif 230w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/d1af7/1440p_Settings_Task_Video.avif 460w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/7f308/1440p_Settings_Task_Video.avif 920w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/c986f/1440p_Settings_Task_Video.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c9efc152b01216e3c7f0c2d5a7049475/a0b58/1440p_Settings_Task_Video.webp 230w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/bc10c/1440p_Settings_Task_Video.webp 460w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/966d8/1440p_Settings_Task_Video.webp 920w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/b8544/1440p_Settings_Task_Video.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9efc152b01216e3c7f0c2d5a7049475/81c8e/1440p_Settings_Task_Video.png 230w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/08a84/1440p_Settings_Task_Video.png 460w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/c0255/1440p_Settings_Task_Video.png 920w", "/en/static/c9efc152b01216e3c7f0c2d5a7049475/e2e58/1440p_Settings_Task_Video.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c9efc152b01216e3c7f0c2d5a7049475/c0255/1440p_Settings_Task_Video.png",
              "alt": "Web User Interface - 1440p Series - Recording",
              "title": "Web User Interface - 1440p Series - Recording",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoTable mdxType="VideoTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      